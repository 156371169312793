<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col class="text-left">
        <h4 class="text--white">Employee Activity</h4>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <b-card class="card-border text--black">
        <b-form
          inline
          @submit.prevent="search"
        >
          <label class="mr-1">Employee:</label>
          <b-form-select
            v-model="form.employee_id"
            class="mr-3"
            :options="formdata.employees"
          >
            <template #first>
              <b-form-select-option :value="null">Select User</b-form-select-option>
            </template>
          </b-form-select>

          <label class="mr-1">Type:</label>
          <b-form-select
            v-model="form.tasktype_id"
            class="mr-3"
            :options="formdata.tasktypes"
          >
            <template #first>
              <b-form-select-option :value="null">Task Type</b-form-select-option>
            </template>
          </b-form-select>

          <label class="mr-1">Year:</label>
          <b-form-select
            v-model="form.year"
            class="mr-3"
            :options="formdata.years"
          >
            <template #first>
              <b-form-select-option :value="null">Year</b-form-select-option>
            </template>
          </b-form-select>

          <label class="mr-1">Month:</label>
          <b-form-select
            v-model="form.month"
            class="mr-3"
            :options="formdata.months"
          >
            <template #first>
              <b-form-select-option :value="null">Month</b-form-select-option>
            </template>
          </b-form-select>

          <b-button
            variant="success"
            type="submit"
          >Search</b-button>
        </b-form>

        <b-table
          id="activityTable"
          class="my-5 small"
          v-if="results"
          :fields="headers"
          :items="results"
          :sort-by.sync="sort.column"
          :sort-desc.sync="sort.reverse"
          bordered
          sticky-header="600px"
          show-empty
          primary-key="employee_id"
          tbody-tr-class="align-middle"
          details-td-class="w-100 p-3"
          tfoot-tr-class="align-middle"
          striped
          @sort-changed="(ctx) => $emit('sort-changed', ctx)"
        >
          <template #cell(employee_id)="data">
            <b-icon
              :icon="(data.detailsShowing) ? 'chevron-up' : 'chevron-down'"
              class="pointer"
              @click.prevent="() => $set(data.item, '_showDetails', !data.item._showDetails)"
            >
            </b-icon>
          </template>

          <template #row-details="row">
            <b-table-simple>
              <b-thead>
                <b-tr>
                  <b-th class="text-left">Type</b-th>
                  <b-th
                    v-for="(s, sidx) in getDetailTaskStatuses(row.item.types)"
                    :key="s"
                  >{{ sidx }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="t in getDetailTaskTypes(row.item.types)"
                  :key="t"
                >
                  <b-th class="text-left">{{ t }}</b-th>
                  <b-td
                    v-for="(s, sidx) in getDetailTaskStatuses(row.item.types)"
                    :key="s"
                  >
                    {{ parseInt(row.item.types[t][sidx]) || 0 }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <b-button
              type="button"
              variant="info"
              @click.prevent="viewDetail(row.item)"
            >View Detailed Activity</b-button>
          </template>
        </b-table>
      </b-card>
    </b-overlay>

    <ActivityDetail
      :show="showActivityDetail"
      :data="selectedEmployee"
      :filters="form"
    ></ActivityDetail>
  </b-container>
</template>
<script>
import EmployeeService from '@/services/EmployeeService'
import ActivityDetail from '@/components/admin/employee/ActivityDetail'

export default {
  name: 'EmployeeActivity',
  components: { ActivityDetail },
  data () {
    return {
      loading: false,
      formdata: {
        years: [],
        months: [],
        statuses: [],
        tasktypes: [],
        employees: []
      },
      form: {
        employee_id: null,
        tasktype_id: null,
        year: null,
        month: null
      },
      sort: {
        column: 'employee',
        reverse: false
      },

      results: null,

      showActivityDetail: false,
      selectedEmployee: null,

      headers: [
        {
          key: 'employee',
          label: 'Employee',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'align-middle text-left'
        },
        {
          key: 'open',
          label: 'Open Tasks',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'complete',
          label: 'Completed',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'notes',
          label: 'Notes',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'employee_id',
          label: '',
          tdClass: 'align-middle'
        }
      ]
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      this.form.year = (new Date()).getFullYear()
      EmployeeService.activityInit().then((response) => {
        this.formdata.years = []
        response.data.info.years.forEach((y) => {
          this.formdata.years.push({ text: y, value: +y })
        })
        this.formdata.months = response.data.info.months
        this.formdata.statuses = response.data.info.statuses
        this.formdata.tasktypes = []
        response.data.info.tasks.forEach((t) => {
          this.formdata.tasktypes.push({ text: t.type, value: +t.id })
        })
        this.formdata.employees = []
        response.data.info.employees.forEach((e) => {
          this.formdata.employees.push({ text: e.lastname + ', ' + e.firstname, value: +e.id })
        })

        this.formdata.employees = this.formdata.employees.sort((a, b) => ('' + a.text).localeCompare(b.text))
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    search () {
      if (this.loading) {
        return false
      }

      this.loading = true
      EmployeeService.activityReport(this.form).then((response) => {
        this.results = response.data.info
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    getDetailTaskTypes (types) {
      const ret = []
      for (const [key, value] of Object.entries(types)) {
        ret.push(key)
        this.$aimNotify.notify(null, value, null, null)
      }
      return ret
    },

    getDetailTaskStatuses (types) {
      const ret = {}
      for (const [key, value] of Object.entries(types)) {
        this.$aimNotify.notify(null, key, null, null)
        for (const [k, v] of Object.entries(value)) {
          ret[k] = v
        }
      }
      return ret
    },

    viewDetail (record) {
      this.selectedEmployee = record
      this.showActivityDetail = false
      this.$nextTick(() => {
        this.showActivityDetail = true
      })
    }
  }
}
</script>
