<template>
  <b-modal
    v-model="showModal"
    size="xl"
    :title="modalTitle"
    button-size="sm"
    ok-only
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-overlay :show="loading">
      <b-table
        :fields="headers"
        :items="results"
        small
        striped
        bordered
      >
      </b-table>
    </b-overlay>
  </b-modal>
</template>
<script>
import EmployeeService from '@/services/EmployeeService'

export default {
  name: 'ActivityDetail',
  props: {
    show: Boolean,
    data: Object,
    filters: Object
  },
  data () {
    return {
      loading: false,
      showModal: false,
      results: null,

      headers: [
        {
          key: 'employee',
          label: 'Employee',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'align-middle text-left'
        },
        {
          key: 'company',
          label: 'Company',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'align-middle text-left'
        },
        {
          key: 'tasktype',
          label: 'Task',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'notes',
          label: 'Notes',
          sortable: true
        }
      ]
    }
  },

  methods: {
    load () {
      if (this.loading) {
        return false
      }
      this.loading = true
      this.results = null
      const f = JSON.parse(JSON.stringify(this.filters))
      f.employee_id = this.data.employee_id
      EmployeeService.activityDetail(f).then((response) => {
        this.results = response.data.info
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    }
  },

  computed: {
    modalTitle: function () {
      if (this.data && this.data.employee) {
        return this.data.employee + ' Activity Report'
      } else {
        return 'Activity Report'
      }
    }
  },

  watch: {
    show: function (n) {
      this.showModal = n
      if (this.showModal) {
        this.load()
      }
    }
  }
}
</script>
