import axios from 'axios'

const EmployeeService = {
  async activityInit () {
    return axios({
      url: axios.defaults.baseURL + 'admin/employee/activity/init',
      method: 'GET'
    })
  },

  async activityReport (data) {
    return axios({
      url: axios.defaults.baseURL + 'admin/employee/activity/report',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async activityDetail (data) {
    return axios({
      url: axios.defaults.baseURL + 'admin/employee/activity/detail',
      method: 'POST',
      data: JSON.stringify(data)
    })
  }
}

export default EmployeeService
